<template>
    <div id="mapDiv"></div>
</template>
<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { RulerControl,StylesControl,ZoomControl  } from 'mapbox-gl-controls';
import { mapConfig } from '@/config/map.js'
// import LeaderLine from 'leader-line';
export default {
    data() {
        return {
            map: null,
            minZoom: 6,
            maxZoom: 20,
            zoom: 12,
            newBounds: mapConfig.tilesBounds,
            accessToken: mapConfig.accessToken,
            rainImg: require("@/assets/visual/humidity.png"),
            sensor: require("@/assets/visual/传感器50px.png"),
            lineImg: require("@/assets/visual/竖线.png"),
            popupConfig: {
                closeOnClick: false,
                focusAfterOpen: true,
                closeButton: false,
                anchor: 'bottom',
                className: 'tipDiv'
            }
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            mapboxgl.accessToken = this.accessToken;
            //天地图（各个区域的token可以在网上查到）
            //实例化Map地图对象
            this.map = new mapboxgl.Map({
                //地图容器div的id
                container: "mapDiv",
                style: "mapbox://styles/mapbox/satellite-streets-v12",
                preserveDrawingBuffer: true,
                //地图中心点
                center: [107.2403283367184343, 30.6105297190023435],
                //地图当前缩放级数
                // zoom: this.zoom,
                zoom: 16,
                // maxZoom: this.maxZoom,
                maxZoom: 16,
                minZoom: this.minZoom,
                pitch: 85,
                bearing: 120.98
            });
            this.map.on("load", () => {
                // let newBounds = this.newBounds.split(",").map((item) => Number(item));
                // let addSources = {
                //     type: "raster",
                //     tiles: ["https://gis.zkzktech.cn/dazhu/hengliangzi/{z}/{x}/{y}.png"],
                //     bounds: newBounds,
                // };
                // let addLayers = {
                //     id: "Marking",
                //     type: "raster",
                //     source: "Marking",
                //     tiles: ["https://gis.zkzktech.cn/dazhu/hengliangzi/{z}/{x}/{y}.png"],
                //     layout: {
                //         visibility: "visible",
                //     },
                //     maxZoom: 16,
                // };
                // this.map.addSource("Marking", addSources);
                // this.map.addLayer(addLayers);
                // this.map.fitBounds([
                //     [newBounds[0], newBounds[1]], // southwestern corner of the bounds
                //     [newBounds[2], newBounds[3]], // northeastern corner of the bounds
                // ]);

                // this.map.addSource("radar", {
                //     type: "image",
                //     url: require("@/assets/atmosphere/中雨.png"),
                //     coordinates: [
                //         // [107.25004, 30.60993], //上左
                //         // [107.24948, 30.60993], //上右
                //         // [107.24948, 30.61049], //下右
                //         // [107.25004, 30.61049], //下左
                //         /* [107.24948, 30.61049], //上左
                //         [107.25006, 30.61049], //上右
                //         [107.25006, 30.61], //下右
                //         [107.24948, 30.61], //下左 */
                //         // 横纬竖经  维度0.00058（/2=0.00029）  经度0.00049
                //         [107.25499011782602, 30.612648736900973],
                //         [107.25557011782602, 30.612648736900973],
                //         [107.25557011782602, 30.612158736900973],
                //         [107.25499011782602, 30.612158736900973],
                //     ],
                // });
                // this.map.addSource("radar2", {
                //     type: "image",
                //     // url: require("@/assets/Image2.png"),
                //     url: require("@/assets/atmosphere/中雨.png"),
                //     coordinates: [
                //         // [107.25299011782602, 30.612603736900973],
                //         // [107.25357011782602, 30.612603736900973],
                //         // [107.25357011782602, 30.612113736900973],
                //         // [107.25299011782602, 30.612113736900973],
                //         // 横纬竖经  维度0.00058  经度0.00049
                //         [107.25299011782602, 30.612848736900973],
                //         [107.25357011782602, 30.612848736900973],
                //         [107.25357011782602, 30.612358736900973],
                //         [107.25299011782602, 30.612358736900973],
                //     ],
                // });
                // this.map.addLayer({
                //     id: "radar-layer",
                //     type: "raster",
                //     source: "radar",
                //     paint: {
                //         "raster-fade-duration": 0,
                //     },
                // });
                // this.map.addLayer({
                //     id: "radar-layer2",
                //     type: "raster",
                //     source: "radar2",
                //     paint: {
                //         "raster-fade-duration": 0,
                //     },
                // });
                this.map.loadImage(this.sensor, (error, image) => {
                    if (error) throw error;
                    // Add the image to the map style.
                    this.map.addImage("sensor", image);
                    // Add a data source containing one point feature.
                    this.map.addSource("point", {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: [
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: [107.25328011782602, 30.612358736900973],
                                    },
                                    properties: {
                                        title: 'Mapbox DC'
                                    }
                                },
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: [107.25528011782602, 30.612158736900973],
                                    },
                                    properties: {
                                        title: 'Mapbox DC'
                                    }
                                },
                            ],
                        },
                    });

                    // Add a layer to use the image to represent the data.
                    this.map.addLayer({
                        id: "points",
                        type: "symbol",
                        source: "point", // reference the data source
                        layout: {
                            "icon-image": "sensor", // reference the image
                            "icon-size": 1.25,
                            // get the title name from the source's "title" property
                            'text-field': ['get', 'title'],
                            'text-font': [
                                'Open Sans Semibold',
                                'Arial Unicode MS Bold'
                            ],
                            'text-offset': [0, 1],
                            'text-anchor': 'top'
                        },
                        minzoom: 14, //缩放等级小于11被隐藏
                    });
                    this.addCustomToMap();
                });
                this.addCustomToMap();
                this.map.addControl(new RulerControl(), 'top-right');
                this.map.addControl(new StylesControl(), 'top-left');
                this.map.addControl(new ZoomControl(), 'top-right');
            });
        },
        addCustomToMap: function () {
            /*  const geojson = {
                 'type': 'FeatureCollection',
                 'features': [
                     {
                         'type': 'Feature',
                         'properties': {
                             'message': 'Foo',
                             'iconSize': [50, 50]
                         },
                         'geometry': {
                             'type': 'Point',
                             'coordinates': [107.25328011782602, 30.612358736900973]
                         }
                     },
                     {
                         'type': 'Feature',
                         'properties': {
                             'message': 'Bar',
                             'iconSize': [50, 50]
                         },
                         'geometry': {
                             'type': 'Point',
                             'coordinates': [107.25528011782602, 30.612158736900973],
                         }
                     }
                 ]
             }; */
            // Add markers to the map.
            /* for (const marker of geojson.features) {
                const outbox = document.createElement('div');
                // 提示信息框
                const tipbox = document.createElement("div");
                // 传感器div
                const el = document.createElement('div');
                // 外层盒子绑定className
                outbox.className = "outbox";
                // 提示信息框绑定className
                tipbox.className = "tipBox";
                const width = marker.properties.iconSize[0];
                const height = marker.properties.iconSize[1];
                el.className = 'marker';
                el.style.backgroundImage = `url(${this.sensor})`;
                el.style.width = `${width}px`;
                el.style.height = `${height}px`;
                el.style.backgroundSize = 'contain';
                el.style.backgroundRepeat = 'no-repeat';

                outbox.appendChild(el);
                outbox.appendChild(tipbox);

                // Add markers to the map.
                new mapboxgl.Marker(outbox)
                    .setLngLat(marker.geometry.coordinates)
                    .addTo(this.map);
                this.$nextTick(() => {
                    new LeaderLine(
                        el,
                        tipbox
                    );
                });
            } */
            new mapboxgl.Popup(this.popupConfig)
                .setLngLat([107.25328011782602, 30.612358736900973])
                .setHTML("<div class='item9'><div class='top-title'><div></div><span class='title'>站点1</span></div><ul class='data-box'><li>降水量：11mm</li><li>湿度：23%</li><li>风速：11m/s</li><li>辐射量：120W/m²</li></ul></div>")
                .addTo(this.map);
        }
    },
};
</script>
<style lang="scss">
#mapDiv {
    width: 100%;
    height: 100%;
}

.outbox {
    width: 50px;
    height: 50px;
    background-color: #fff;
    position: absolute;

    .tipBox {
        position: relative;
        left: 75px;
        top: -150px;
        width: 120px;
        height: 100px;
        border: 1px solid saddlebrown;
    }
}

.tipDiv {
    background-color: transparent;
}

.mapboxgl-popup-content {
    background: transparent;
    box-shadow: none;
    padding: 5px;
    background: linear-gradient(to left, #2CD5FF, #2CD5FF) left top no-repeat,
        linear-gradient(to bottom, #2CD5FF, #2CD5FF) left top no-repeat,
        linear-gradient(to left, #2CD5FF, #2CD5FF) right top no-repeat,
        linear-gradient(to bottom, #2CD5FF, #2CD5FF) right top no-repeat,
        linear-gradient(to left, #2CD5FF, #2CD5FF) left bottom no-repeat,
        linear-gradient(to bottom, #2CD5FF, #2CD5FF) left bottom no-repeat,
        linear-gradient(to left, #2CD5FF, #2CD5FF) right bottom no-repeat,
        linear-gradient(to left, #2CD5FF, #2CD5FF) right bottom no-repeat;
    background-size: 2px 15px, 15px 2px, 2px 15px, 15px 2px;
    border: 1px solid #073F97;
    position: relative;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: rgb(53, 137, 182, 0.4);
}

.item9 {
    // width: 100px;
    background-color: rgba(19, 35, 60, 0.4);
    color: #90e1f4;
    font-size: 15px;

    .top-title {
        display: flex;
        align-items: center;
        div:first-child {
            width: 15px;
            height: 15px;
            background: center/contain url("~@/assets/visual/device-position.svg") no-repeat;
        }
        span {
            transform: scale(0.8);
        }
    }
    .data-box{
        width: 100%;
        user-select:none ;
        li{
            padding-right: 5px;
            list-style-type:circle;
            transform: scale(0.7);
        }
    }
}
</style>
  